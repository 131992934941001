import React from "react"

import Layout from '../../components/layout'

import { Hero } from '../../components/hero'
import { WhyBodyMedGroup } from '../../components/whyBodyMed'
import { MostPopular } from '../../components/mostPopular'
import { Newsletter } from '../../components/common/newsletter' 
import { CustomerComments } from '../../components/customerComments'
import { ArticleSlider } from '../../components/blog/articleSlider'
import Seo from '../../components/seo'

const IndexPageBydgoszcz = () => (
  <Layout bydgoszcz>
    <Seo
      title="Klinika Kosmetologii Estetycznej Bydgoszcz - BodyMed Group"
      description="Klinika kosmetologii estetycznej BodyMed Group to wyjątkowe miejsce w Bydgoszczy, które oferuje nowoczesne i profesjonalne zabiegi z obszaru kosmetologii estetycznej."
      url="https://bodymedgroup.pl/bydgoszcz"
    />
    <Hero bydgoszcz/>
    <WhyBodyMedGroup bydgoszcz/>
    <MostPopular bydgoszcz/>
    <Newsletter/>
    <CustomerComments/>
    <ArticleSlider bydgoszcz/>
  </Layout>
)

export default IndexPageBydgoszcz
